<template>
  <div>
    <!-- search input -->
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="feedbacks"
        :isLoading="isDataLoading"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Icon -->

          <span v-if="props.column.field === 'fullName'">
              {{ props.row.fullName }}
          </span>

          <span v-else-if="props.column.field === 'email'">
            <address> <a :href="'mailto:'+props.row.email"> {{ props.row.email }}</a></address>
          </span>

          <span v-else-if="props.column.field === 'subject'">
              {{props.row.subject}}
          </span>


        <span v-else-if="props.column.field === 'createdAt'">
            <b-badge variant="light-success">
              {{getFormattedData(props.row.createdAt)}}
            </b-badge>
          </span>

        <span v-else-if="props.column.field === 'updatedAt'">
            <b-badge variant="light-success">
              {{getFormattedData(props.row.updatedAt)}}
            </b-badge>
        </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
             <div  style="float: left" class="mt-1">
                   <b-button @click="downloadCSV" variant="danger"
              >Download CSV</b-button
            >
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
  },
  data() {
    return {
      feedbacks: [],
      itemsToTakeAction: [],
      isDataLoading: true,
      pageLength: 15,
      dir: false,
      columns: [
        {
          label: "Name",
          field: "fullName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
            label: "Created At",
            field: "createdAt"  
        },
            {
            label: "Updated At",
            field: "updatedAt"  
        }
      ],
      searchTerm: "",
    };
  },
  methods: {
    getFeedbacks() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/feedback/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.feedbacks = response.data;
          this.isDataLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isDataLoading = false;
        });
    },
    downloadCSV() {
        axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/feedbackdownload`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                     var fileLink = document.createElement('a');
   
                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', 'Feedback.csv');
                     document.body.appendChild(fileLink);
   
                     fileLink.click();
          console.log(res.data)
        })
    },
    openMail(mailId) {
        console.log('open mail')
          window.location.href = "mailto"+mailId;
    },
    getFormattedData(date) {
      return moment(date).tz("Asia/Calcutta").format("DD-MM-YYYY HH:MM:SSa");
    },
  },
  created() {
    this.getFeedbacks();
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
